import { AuthCtrl } from "@/mixins/apis_v2/internal/core/AuthCtrl";

export const ModelCtrl = {
  data() {
    return {};
  },
  mixins: [AuthCtrl],
  created() {},
  methods: {
    // CREAT
    // 인증된 사용자를 위한 정보 생성 API "use AUTH_AXIOS"
    async authCreateModel(url, model, showLog) {
      if (showLog) {
        console.log(
          `%c[ mixins: ModelCtrl ] [ ` +
            `%c${url}` +
            `%c ] authCreateModel model :`,
          `color: #008080;`,
          `color: #008080;`,
          `color: #008080;`,
          model
        );
      }
      await this.setAxios("auth", showLog);
      let tempData = null;
      await this.AUTH_AXIOS.post(url, model)
        .then((res) => {
          if (showLog) {
            console.log(
              `%c[ ` + `%c${url}` + `%c ] authCreateModel res :`,
              `color: #008080;`,
              `color: #008080;`,
              `color: #008080;`,
              res
            );
          }
          tempData = res;
        })
        .catch((err) => {
          console.log(
            `%c[ Error ] [ ${url} ] Auth Create err : ${err}`,
            `color: #FD1C03;`
          );
        });
      this.clearAxios(showLog);
      return tempData;
    },
    // 인증되지 않은 사용자를 위한 정보 생성 API "use OPEN_AXIOS"
    async openCreateModel(url, model, showLog) {
      if (showLog) {
        console.log(
          `%c[ mixins: ModelCtrl ] [ ` +
            `%c${url}` +
            `%c ] openCreateModel model :`,
          `color: #008080;`,
          `color: #008080;`,
          `color: #008080;`,
          model
        );
      }
      await this.setAxios("open", showLog);
      let tempData = null;
      await this.OPEN_AXIOS.post(url, model)
        .then((res) => {
          if (showLog) {
            console.log(
              `%c[ ` + `%c${url}` + `%c ] openCreateModel res :`,
              `color: #008080;`,
              `color: #008080;`,
              `color: #008080;`,
              res
            );
          }
          tempData = res;
        })
        .catch((err) => {
          console.log(
            `%c[ Error ] [ ${url} ] Open Create err : ${err}`,
            `color: #FD1C03;`
          );
        });
      this.clearAxios(showLog);
      return tempData;
    },

    // READ
    // 인증된 사용자를 위한 정보 조회 API "use AUTH_AXIOS"
    async authReadModel(url, paramsObject, showLog) {
      if (paramsObject === "undefined") {
        if (showLog) {
          console.log(
            `%c[ mixins: ModelCtrl ] [ ` + `%c${url}` + `%c ] authReadModel`,
            `color: #008080;`,
            `color: #008080;`,
            `color: #008080;`
          );
        }
        await this.setAxios("auth", showLog);
        let tempData = null;
        await this.AUTH_AXIOS.get(url)
          .then((res) => {
            if (showLog) {
              console.log(
                `%c[ ` + `%cread res` + `%c ] :`,
                `color: #008080;`,
                `color: #008080;`,
                `color: #008080;`,
                res
              );
            }
            tempData = res;
          })
          .catch((err) => {
            console.log(
              `%c[ Error ] [ ${url} ] Auth Read err : ${err}`,
              `color: #FD1C03;`
            );
          });
        this.clearAxios(showLog);
        if (tempData == null) {
          return tempData;
        }
        return tempData;
      } else {
        if (showLog) {
          console.log(
            `%c[ mixins: ModelCtrl ] [ ${url} ] authReadModel paramsObject :`,
            `color: #008080;`,
            paramsObject
          );
        }
        await this.setAxios("auth", showLog);
        let tempData = null;
        await this.AUTH_AXIOS.get(url, paramsObject)
          .then((res) => {
            if (showLog) {
              console.log(
                `%c[ ${url} ] Auth Read res : `,
                `color: #008080;`,
                res
              );
            }
            tempData = res;
          })
          .catch((err) => {
            console.log(
              `%c[ Error ] [ ${url} ] Auth Read err : ${err}`,
              `color: #FD1C03;`
            );
          });
        this.clearAxios(showLog);
        return tempData;
      }
    },
    // 인증되지 않은 사용자를 위한 정보 조회 API "use OPEN_AXIOS"
    async openReadModel(url, paramsObject, showLog) {
      if (paramsObject === "undefined") {
        if (showLog) {
          console.log(
            `%c[ mixins: ModelCtrl ] [ ` + `%c${url}` + `%c ] openReadModel`,
            `color: #008080;`,
            `color: #008080;`,
            `color: #008080;`
          );
        }
        // console.log("%ccheck", "color:red;");
        await this.setAxios("open", showLog);
        // console.log("%caxios", "color:red;", this.OPEN_AXIOS);
        let tempData = null;
        // console.log("%ccheck", "color:red;");
        await this.OPEN_AXIOS.get(url)
          .then((res) => {
            if (showLog) {
              console.log(
                `%c[ ` + `%cread res` + `%c ] :`,
                `color: #008080;`,
                `color: #008080;`,
                `color: #008080;`,
                res
              );
            }
            tempData = res;
          })
          .catch((err) => {
            console.log(
              `%c[ Error ] [ ${url} ] Open Read err : ${err}`,
              `color: #FD1C03;`
            );
          });
        this.clearAxios(showLog);
        return tempData;
      } else {
        if (showLog) {
          console.log(
            `%c[ mixins: ModelCtrl ] [ ` +
              `%c${url}` +
              `%c ] openReadModel paramsObject :`,
            `color: #008080;`,
            `color: #008080;`,
            `color: #008080;`,
            paramsObject
          );
        }
        await this.setAxios("open", showLog);
        let tempData = null;
        await this.OPEN_AXIOS.get(url, paramsObject)
          .then((res) => {
            if (showLog) {
              console.log(
                `%c[ ` + `%cread res` + `%c ] :`,
                `color: #008080;`,
                `color: #008080;`,
                `color: #008080;`,
                res
              );
            }
            tempData = res;
          })
          .catch((err) => {
            console.log(
              `%c[ Error ] [ ${url} ] Open Read err : ${err}`,
              `color: #FD1C03;`
            );
          });
        this.clearAxios(showLog);
        return tempData;
      }
    },

    // UPDATE
    // 인증된 사용자를 위한 정보 수정 API "use AUTH_AXIOS"
    async authUpdateModel(url, modelWithId, showLog) {
      if (showLog) {
        console.log(
          `%c[ mixins: ModelCtrl ] [ ` +
            `%c${url}` +
            `%c ] authUpdateModel modelWithId :`,
          `color: #008080;`,
          `color: #008080;`,
          `color: #008080;`,
          modelWithId
        );
      }
      await this.setAxios("auth", showLog);

      let tempData = null;
      await this.AUTH_AXIOS.put(url, modelWithId)
        .then((res) => {
          if (showLog) {
            console.log(
              `%c[ ` + `%cupdate res` + `%c ] :`,
              `color: #008080;`,
              `color: #008080;`,
              `color: #008080;`,
              res
            );
          }
          tempData = res;
        })
        .catch((err) => {
          console.log(
            `%c[ Error ] [ ${url} ] Auth Update err : ${err}`,
            `color: #FD1C03;`
          );
        });
      this.clearAxios(showLog);
      return tempData;
    },
    // 인증되지 않은 사용자를 위한 정보 수정 API "use OPEN_AXIOS"
    async openUpdateModel(url, modelWithId, showLog) {
      if (showLog) {
        console.log(
          `%c[ mixins: ModelCtrl ] [ ` +
            `%c${url}` +
            `%c ] openUpdateModel modelWithId :`,
          `color: #008080;`,
          `color: #008080;`,
          `color: #008080;`,
          modelWithId
        );
      }
      await this.setAxios("open", showLog);
      let tempData = null;
      await this.OPEN_AXIOS.put(url, modelWithId)
        .then((res) => {
          if (showLog) {
            console.log(
              `%c[ ` + `%cupdate res` + `%c ] :`,
              `color: #008080;`,
              `color: #008080;`,
              `color: #008080;`,
              res
            );
          }
          tempData = res;
        })
        .catch((err) => {
          console.log(
            `%c[ Error ] [ ${url} ] Open Update err : ${err}`,
            `color: #FD1C03;`
          );
        });
      this.clearAxios(showLog);
      return tempData;
    },

    // DELETE
    // 인증된 사용자를 위한 정보 삭제 API "use AUTH_AXIOS"
    async authDeleteModel(url, dataObject, showLog) {
      if (showLog) {
        console.log(
          `%c[ mixins: ModelCtrl ] [ ` +
            `%c${url}` +
            `%c ] authDeleteModel dataObject :`,
          `color: #008080;`,
          `color: #008080;`,
          `color: #008080;`,
          dataObject
        );
      }
      await this.setAxios("auth", showLog);

      let tempData = null;
      await this.AUTH_AXIOS.delete(url, dataObject)
        .then((res) => {
          if (showLog) {
            console.log(
              `%c[ ` + `%cdelete res` + `%c ] :`,
              `color: #008080;`,
              `color: #008080;`,
              `color: #008080;`,
              res
            );
          }
          tempData = res;
        })
        .catch((err) => {
          console.log(
            `%c[ Error ] [ ${url} ] Auth Delete err : ${err}`,
            `color: #FD1C03;`
          );
        });
      this.clearAxios(showLog);
      return tempData;
    },
    // 인증되지 않은 사용자를 위한 정보 삭제 API "use OPEN_AXIOS"
    async openDeleteModel(url, dataObject, showLog) {
      if (showLog) {
        console.log(
          `%c[ mixins: ModelCtrl ] [ ` +
            `%c${url}` +
            `%c ] openDeleteModel dataObject :`,
          `color: #008080;`,
          `color: #008080;`,
          `color: #008080;`,
          dataObject
        );
      }
      await this.setAxios("open", showLog);
      let tempData = null;
      await this.OPEN_AXIOS.delete(url, dataObject)
        .then((res) => {
          if (showLog) {
            console.log(
              `%c[ ` + `%cdelete res` + `%c ] :`,
              `color: #008080;`,
              `color: #008080;`,
              `color: #008080;`,
              res
            );
          }
          tempData = res;
        })
        .catch((err) => {
          console.log(
            `%c[ Error ] [ ${url} ] Open Delete err : ${err}`,
            `color: #FD1C03;`
          );
        });
      this.clearAxios(showLog);
      return tempData;
    },
  },
};
