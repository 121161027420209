<template>
  <v-app class="background-color">
    <v-card flat :style="main_style" class="overflow-hidden">
      <NavgationDrawers :drawer="drawer" @closeDrawer="drawer = false" />
      <v-app-bar
        :class="v_app_bar_padding"
        absolute
        color="#edeef3"
        scroll-target="#scrolling-techniques-7"
        :style="main_style"
        elevate-on-scroll
        fixed
        app
      >
        <v-app-bar-nav-icon>
          <v-app-bar-nav-icon @click="drawer = !drawer" />
        </v-app-bar-nav-icon>

        <v-toolbar-title class="pa-2" :style="title_text_size">
          <router-link
            style="text-decoration: none; color: inherit; font-size: 1.2rem"
            :to="'/'"
          >
            YEWON BIZ
          </router-link>
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <div v-if="$vuetify.breakpoint.mobile">
          <v-btn
            class="pt-1 ml-5"
            plain
            :style="menu_text_size"
            v-if="!isLogin"
          >
            <router-link
              style="text-decoration: none; color: inherit"
              to="/sub/sign-up"
            >
              회원가입
            </router-link>
          </v-btn>
          <v-btn class="pt-1 ml-5" plain :style="menu_text_size" v-if="isLogin">
            <router-link
              style="text-decoration: none; color: inherit"
              to="/mypage/user-info"
            >
              내 정보
            </router-link>
          </v-btn>
        </div>

        <div v-if="$vuetify.breakpoint.mdAndUp">
          <v-btn class="mt-1" plain :style="menu_text_size">
            <router-link
              style="text-decoration: none; color: inherit"
              to="/yewon-biz"
            >
              소개
            </router-link>
          </v-btn>
          <v-btn class="mt-1" plain :style="menu_text_size">
            <router-link
              style="text-decoration: none; color: inherit"
              to="/industrialist"
            >
              예원산업인
            </router-link>
          </v-btn>
          <v-btn class="mt-1" plain :style="menu_text_size">
            <router-link
              style="text-decoration: none; color: inherit"
              to="/yewon-biz-news"
            >
              예원비즈뉴스
            </router-link>
          </v-btn>
          <v-btn class="mt-1" plain :style="menu_text_size" v-if="!isLogin">
            <router-link
              style="text-decoration: none; color: inherit"
              to="/sub/login"
            >
              로그인
            </router-link>
          </v-btn>
          <v-btn class="mt-1" plain :style="menu_text_size" v-if="!isLogin">
            <router-link
              style="text-decoration: none; color: inherit"
              to="/sub/sign-up"
            >
              회원가입
            </router-link>
          </v-btn>
          <v-btn
            plain
            class="mt-1"
            :style="menu_text_size"
            v-if="isLogin"
            @click="logout()"
          >
            <router-link style="text-decoration: none; color: inherit" to="/">
              로그아웃
            </router-link>
          </v-btn>

          <v-btn
            href="/mypage/user-info"
            class="mt-1"
            plain
            :style="menu_text_size"
            v-if="isLogin"
          >
            마이페이지
          </v-btn>
          <v-avatar class="mt-0" color="primary" size="38" v-if="isLogin">
            <router-link
              to="/mypage/user-info"
              tag="img"
              aspect-ratio="1"
              :src="profileFilepath"
            ></router-link>
          </v-avatar>
        </div>
      </v-app-bar>
      <v-sheet
        id="scrolling-techniques-7"
        class="overflow-y-auto"
        max-height="600"
      >
        <v-container style="height: 84px"> </v-container>
      </v-sheet>
    </v-card>

    <v-main class="pt-3" app>
      <router-view />
    </v-main>

    <v-footer padless>
      <v-container fluid class="background-color">
        <v-row>
          <v-col cols="12" sm="6" md="4" lg="4" xl="4">
            <v-card
              class="flex background-color"
              :class="footer_v_card_padding"
              flat
              v-if="$vuetify.breakpoint.mdAndUp"
            >
              <span :style="footer_text_left_title">YEWON BIz </span><br />
              <span :style="footer_text_left_content">
                +44 345 678 903 <br />
                CONTACT US <br />
                adobexd@mail.com
              </span>
            </v-card>
          </v-col>
          <v-col
            cols="4"
            sm="4"
            md="4"
            lg="4"
            xl="4"
            v-if="$vuetify.breakpoint.mdAndUp"
            class="pl-7 text-center footer-text-message"
            :class="footer_v_spacer_padding"
            >The Industrial Mission Committee prays for <br />
            the blessing of the economy of light to all industrialists in Yewon.
          </v-col>

          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="4"
            xl="4"
            :class="footer_text_right_class"
          >
            <v-btn
              v-for="(icon, i) in icons"
              :key="i"
              class="mx-4 nm"
              color="#7D9AA5"
              icon
              :href="icon.to"
              target="_blank"
            >
              <v-icon size="24px"> {{ icon.icon }} </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>
import NavgationDrawers from "@/components/NavgationDrawers.vue";
import { mapState, mapActions } from "vuex";
import { TokenCtrl } from "@/mixins/apis_v2/utility/TokenCtrl";

// MIXINS
// for Data CRUD
import { MasterCtrl } from "@/mixins/apis_v2/internal/MasterCtrl";
// for File Control
import { FileBins } from "@/mixins/apis_v2/internal/FileBins";
// for Utility
import { Utility } from "@/mixins/apis_v2/utility/Utility";

export default {
  name: "MainLayout",
  components: {
    NavgationDrawers,
  },
  data: () => ({
    axios: null,
    profileFilepath:
      "https://images.unsplash.com/photo-1623150502742-6a849aa94be4?crop=entropy&cs=tinysrgb&fm=jpg&ixlib=rb-1.2.1&q=80&raw_url=true&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170",
    drawer: false,
    sidebar: false,
    appTitle: "Awesome App",
    menuItems: [
      { title: "소개", path: "/yewon-biz" },
      { title: "예원산업인", path: "/industrialist" },
      { title: "예원뉴스", path: "/announcement" },
      { title: "로그인", path: "/clean/login" },
      { title: "회원가입", path: "/clean/sign-up" },
    ],
    icons: [
      {
        icon: "mdi-instagram",
        to: "https://www.instagram.com/yewonbiz/?hl=ko",
      },
    ],
  }),
  computed: {
    ...mapState(["isLogin", "userInfo"]),
    main_style() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "height: 74px";
        case "sm":
          return "height: 84px";
        case "md":
          return "height: 84px";
        case "lg":
          return "height: 84px";
        case "xl":
          return "height: 84px";
        default:
          return "";
      }
    },
    // 패딩
    v_app_bar_padding() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "pl-1 pr-3 pt-1 pb-0 ma-0";
        case "sm":
          return "pl-7 pr-7 pt-3";
        case "md":
          return "pl-13 pr-12 pt-3";
        case "lg":
          return "pl-14 pr-12 pt-3";
        case "xl":
          return "pl-16 pr-16 pt-3";
        default:
          return "pl-1 pr-3 pt-0";
      }
    },
    // 탑바 좌 텍스트 크기
    title_text_size() {
      switch (this.$vuetify.breakpoint.name) {
        case "sm":
          return "font-size: 1.0rem";
        case "md":
          return "font-size: 1.1rem";
        case "lg":
          return "font-size: 1.3rem";
        case "xl":
          return "font-size: 1.3rem";
        default:
          return "font-size: 1.0rem";
      }
    },
    // 탑바 우 텍스트 크기
    menu_text_size() {
      switch (this.$vuetify.breakpoint.name) {
        case "sm":
          return "font-size: 0.75rem";
        case "md":
          return "font-size: 0.75rem";
        case "lg":
          return "font-size: 0.9rem";
        case "xl":
          return "font-size: 0.9rem";
        default:
          return "font-size: 0.75rem";
      }
    },
    // 푸터 좌
    // 폰트 크기
    footer_text_left_title() {
      switch (this.$vuetify.breakpoint.name) {
        case "sm":
          return "font-size: 0.85rem; font-weight: 500; color: #545454;";
        case "md":
          return "font-size: 0.85rem; font-weight: 500; color: #545454;";
        case "lg":
          return "font-size: 0.85rem; font-weight: 500; color: #545454;";
        case "xl":
          return "font-size: 0.85rem; font-weight: 500; color: #545454;";
        default:
          return "font-size: 0.85rem; font-weight: 500; color: #545454;";
      }
    },
    footer_text_left_content() {
      switch (this.$vuetify.breakpoint.name) {
        case "sm":
          return "font-size: 0.85rem; font-weight: 500; color: #b2b2b2;";
        case "md":
          return "font-size: 0.85rem; font-weight: 500; color: #b2b2b2;";
        case "lg":
          return "font-size: 0.85rem; font-weight: 500; color: #b2b2b2;";
        case "xl":
          return "font-size: 0.85rem; font-weight: 500; color: #b2b2b2;";
        default:
          return "font-size: 0.85rem; font-weight: 500; color: #b2b2b2;";
      }
    },
    // 좌 패딩
    footer_v_card_padding() {
      switch (this.$vuetify.breakpoint.name) {
        case "sm":
          return "pl-10 pb-5";
        case "md":
          return "pl-14 pb-5";
        case "lg":
          return "pl-15 pb-5";
        case "xl":
          return "pl-16 pb-5";
        default:
          return "text-center";
      }
    },
    // 푸터 중 폰트 크기
    // 푸터 중 패당
    footer_v_spacer_padding() {
      switch (this.$vuetify.breakpoint.name) {
        case "sm":
          return "pt-2";
        case "md":
          return "pt-2";
        case "lg":
          return "pt-2";
        case "xl":
          return "pt-2";
        default:
          return "pt-2";
      }
    },
    // 푸터 우 버튼
    footer_text_right_class() {
      switch (this.$vuetify.breakpoint.name) {
        case "sm":
          return "text-right pr-16 pb-16";
        case "md":
          return "text-right pr-16 pb-16";
        case "lg":
          return "text-right pr-16 pb-16";
        case "xl":
          return "text-right pr-16 pb-16";
        default:
          return "text-center pb-16";
      }
    },
  },
  mixins: [TokenCtrl, MasterCtrl, FileBins, Utility],
  created() {
    this.getUserProfileImage();
  },
  methods: {
    ...mapActions(["checkLogin", "refreshAccessTokenAtVuex", "logout"]),
    async getUserProfileImage() {
      this.lv1_Func("view:Home", "getUserProfileImage()");
      // 로컬 스토리지에서 유저 정보를 가져오기
      let userInfo = JSON.parse(localStorage.getItem("user_info"));
      this.lv1_Data("userInfo", userInfo);
      // 유저 정보에 ID 값을 통해 프로필 이미지를 가져온다.
      // 받아온 Work 정보를 통해 User 정보를 읽어옴
      this.lv1_Act("받아온 Work 정보를 통해 User 정보를 읽어옴");
      const tempUser = await this.openReadDataItemById(this.User, userInfo.id);
      this.lv1_Data("tempUser", tempUser);

      // 받아온 유저정보를 통해 User Profile 정보를 읽어옴
      this.lv1_Act("받아온 유저정보를 통해 User Profile 정보를 읽어옴");
      this.userProfileImage = await this.readUserFileList(userInfo.id, "UP");
      this.lv1_Data("userProfileImage", this.userProfileImage);

      if (this.userProfileImage.length == 0) {
        this.lv1_Case("유저 프로필 이미지 없음");
        this.lv1_Act("디폴트 유저 이미지를 세팅함");
        this.userProfileImage = [
          {
            file_path:
              "https://images.unsplash.com/photo-1612629131312-8f9a21a1b2c9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
          },
        ];
      }
      this.lv1_Data(
        "userProfileImage[0].file_path",
        this.userProfileImage[0].file_path
      );

      this.profileFilepath = this.userProfileImage[0].file_path;
      this.lv1_Data("profileFilepath", this.profileFilepath);
    },
  },
};
</script>

<style>
.footer-text-message {
  font-size: 14px;
  font-weight: 500;
  color: #545454c0 !important;
}

.footer-text-prime {
  font-size: 14px;
  font-weight: 700;
  color: #545454 !important;
}

.footer-text {
  font-size: 14px;
  font-weight: 500;
  color: #b2b2b2 !important;
}
</style>
