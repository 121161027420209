import { ModelCtrl } from "@/mixins/apis_v2/internal/core/ModelCtrl";

export const MasterCtrl = {
  data() {
    return {
      // URL List
      BizNews: "/biznews",
      BNHFile: "/bnhfiles",
      File: "/files",
      User: "/users",
      UserPWUpdate: "/users-pwd",
      UHFile: "/uhfiles",
      Work: "/works",
      WHFile: "/whfiles",
      BusinessKeyword: "/business_keywords",
      Donation: "/donations",
      Account: "/account-util",
      ResetPassword: "/reset-pw",
      ResetPasswordOutside: "/reset-pw-outside",
      // Model List
      modelBizNews: {
        title: "",
        sub_title: "",
        body: "",
      },
      modelBNHFile: {
        biz_news_id: "",
        file_id: "",
      },
      modelFile: {
        file_for: "",
        file_name: "",
        file_title: "",
        file_description: "",
        file_path: "",
        file_size: "",
        file_type: "",
      },
      modelUser: {
        name: "",
        email: "",
        password: "",
        gender_type: "",
        birth_date: "",
        address: "",
        address_detail: "",
        is_address_public: "",
        church_office_idx: "",
        sns_url: "",
        hobby: "",
        user_type: "",
        phone_number: "",
        is_phone_number_public: "",
        church_member_number: "",
        internship_field_idx: "",
        authority_idx: "",
      },
      modelUHFile: {
        user_id: "",
        file_id: "",
      },
      modelWork: {
        user_id: "",
        business_domain_lv1_idx: "",
        business_domain_lv2_idx: "",
        company_name: "",
        job_domain_idx: "",
        job_position_idx: "",
        work_type: "",
        work_description: "",
        work_start_date: "",
        work_end_date: "",
        working_place_address: "",
        working_place_address_detail: "",
        uri_instagram: "",
        uri_homepage: "",
        career_type: "",
        tel: "",
      },
      modelWHFile: {
        work_id: "",
        file_id: "",
      },
      modelBusinessKeyword: {
        work_id: "",
        keyword_name: "",
      },
      modelDonation: {
        donation_type: "",
        donation_name: "",
        user_id: "",
        bank_idx: "",
        bank_account_name: "",
        bank_account_number: "",
        donation_amount: "",
      },
    };
  },
  created() {
    // ToDo
    // [ ] Create 실패할 경우 처리정책 수립
  },
  mixins: [ModelCtrl],
  methods: {
    // Model BizNews
    // CREATE
    /**
     * @description [인증된 사용자] BizNews 생성 API
     * @param {String} url 요청 URL
     * @param {Object} model 생성에 사용할 모델 객체
     * @param {Boolean} showLog 생성 결과를 로그에 표시할지 여부
     * @returns {Object} 조회 결과 (object: 성공, {result:0}: 실패)
     */
    async authCreateData(url, model, showLog) {
      if (showLog) {
        console.log(
          `%c[ ${url} ]` + `%c[ Mixin : MasterCtrl ] authCreateData() model:`,
          `color: #6495ED;`,
          `color: #6495ED;`,
          model
        );
      }
      const res = await this.authCreateModel(url, model, showLog);
      let returnData = res.data;
      if (returnData === null) {
        returnData = { result: 0 };
      }
      if (showLog) {
        console.log(`%c[ return ] :`, `color: #6495ED;`, returnData);
      }
      return returnData;
    },
    /**
     * @description [인증되지 않은 사용자] BizNews 생성 API
     * @param {String} url 요청 URL
     * @param {Object} model 생성에 사용할 모델 객체
     * @param {Boolean} showLog 생성 결과를 로그에 표시할지 여부
     * @returns {Object} 조회 결과 (object: 성공, {result:0}: 실패)
     */
    async openCreateData(url, model, showLog) {
      if (showLog) {
        console.log(
          `%c[ ${url} ]` +
            `%c[ Mixin : MasterCtrl ] openCreateBizNews() model:`,
          `color: #6495ED;`,
          `color: #6495ED;`,
          model
        );
      }
      const res = await this.openCreateModel(url, model, showLog);
      let returnData = res.data;
      if (returnData === null) {
        returnData = { result: 0 };
      }
      if (showLog) {
        console.log(`%c[ return ] :`, `color: #6495ED;`, returnData);
      }
      return returnData;
    },

    // READ
    /**
     * @description [인증된 사용자] BizNews 전체 리스트 조회 API
     * @param {String} url 요청 URL
     * @param {Boolean} showLog 생성 결과를 로그에 표시할지 여부
     * @returns {Array} 조회 결과
     */
    async authReadDataList(url, showLog) {
      if (showLog) {
        console.log(
          `%c[ ${url} ]` + `%c[ Mixin : MasterCtrl ] authReadDataList()`,
          `color: #6495ED;`,
          `color: #6495ED;`
        );
      }
      const res = await this.authReadModel(url, "undefined", showLog);
      const returnData = res.data;
      if (showLog) {
        console.log(`%c[ return ] :`, `color: #6495ED;`, returnData);
      }
      return returnData;
    },
    /**
     * @description [인증된 사용자] ID를 통한 BizNews 조회 API
     * @param {String} url 요청 URL
     * @param {Number} id 조회할 BizNews의 ID
     * @param {Boolean} showLog 생성 결과를 로그에 표시할지 여부
     * @returns {Object} 조회 결과 (object: 성공, {result:0}: 실패)
     */
    async authReadDataItemById(url, id, showLog) {
      if (showLog) {
        console.log(
          `%c[ ${url} ]` +
            `%c[ Mixin : MasterCtrl ] authReadDataItemById() id:`,
          `color: #6495ED;`,
          `color: #6495ED;`,
          id
        );
      }
      let paramsObject = {
        params: {
          id: id,
        },
      };
      const res = await this.authReadModel(url, paramsObject, showLog);
      if (showLog) {
        console.log(`%c[ res ] :`, `color: #6495ED;`, res);
      }
      let returnData = res.data;
      if (returnData === null) {
        returnData = 0;
      }
      if (showLog) {
        console.log(`%c[ return ] :`, `color: #6495ED;`, returnData);
      }
      return returnData;
    },
    /**
     * @description [인증되지 않은 사용자] BizNews 전체 리스트 조회 API
     * @param {String} url 요청 URL
     * @param {Boolean} showLog 생성 결과를 로그에 표시할지 여부
     * @returns {Array} 조회 결과
     */
    async openReadDataList(url, showLog) {
      if (showLog) {
        console.log(
          `%c[ ${url} ]` + `%c[ Mixin : MasterCtrl ] openReadDataList()`,
          `color: #6495ED;`,
          `color: #6495ED;`
        );
      }
      const res = await this.openReadModel(url, "undefined", showLog);
      const returnData = res.data;
      if (showLog) {
        console.log(`%c[ return ] :`, `color: #6495ED;`, returnData);
      }
      return returnData;
    },
    /**
     * @description [인증되지 않은 사용자] ID를 통한 BizNews 조회 API
     * @param {String} url 요청 URL
     * @param {Number} id 조회할 BizNews의 ID
     * @param {Boolean} showLog 생성 결과를 로그에 표시할지 여부
     * @returns {Object} 조회 결과 (object: 성공, {result:0}: 실패)
     */
    async openReadDataItemById(url, id, showLog) {
      if (showLog) {
        console.log(
          `%c[ ${url} ]` +
            `%c[ Mixin : MasterCtrl ] openReadDataItemById() id:`,
          `color: #6495ED;`,
          `color: #6495ED;`,
          id
        );
      }
      let paramsObject = {
        params: {
          id: id,
        },
      };
      const res = await this.openReadModel(url, paramsObject, showLog);
      let returnData = res.data;
      if (returnData === null) {
        returnData = { result: 0 };
      }
      if (showLog) {
        console.log(`%c[ return ] :`, `color: #6495ED;`, returnData);
      }
      return returnData;
    },

    // UPDATE
    /**
     * @description [인증된 사용자] PK를 통한 BizNews 수정 API
     * @param {String} url 요청 URL
     * @param {Number} idOfTarget 수정할 BizNews의 PK
     * @param {Object} modelForUpdate 수정할 데이터 모델
     * @param {Boolean} showLog 생성 결과를 로그에 표시할지 여부
     * @returns {Object} 조회 결과 (object: 성공, {result:0}: 실패)
     */
    async authUpdateData(url, idOfTarget, modelForUpdate, showLog) {
      if (showLog) {
        console.log(
          `%c[ ${url} ]` +
            `%c[ Mixin : MasterCtrl ] authUpdateBizNews() idOfTarget:`,
          `color: #6495ED;`,
          `color: #6495ED;`,
          idOfTarget
        );
      }
      modelForUpdate.id = idOfTarget;

      const res = await this.authUpdateModel(url, modelForUpdate, showLog);
      let returnData = res.data;
      if (returnData === null) {
        returnData = { result: 0 };
      }
      if (showLog) {
        console.log(`%c[ return ] :`, `color: #6495ED;`, returnData);
      }
      return returnData;
    },
    /**
     * @description [인증되지 않은 사용자] PK를 통한 BizNews 수정 API
     * @param {String} url 요청 URL
     * @param {Number} idOfTarget 수정할 BizNews의 PK
     * @param {Object} modelForUpdate 수정할 데이터 모델
     * @param {Boolean} showLog 생성 결과를 로그에 표시할지 여부
     * @returns {Object} 조회 결과 (object: 성공, {result:0}: 실패)
     */
    async openUpdateData(url, idOfTarget, modelForUpdate, showLog) {
      if (showLog) {
        console.log(
          `%c[ ${url} ]` +
            `%c[ Mixin : MasterCtrl ] openUpdateBizNews() idOfTarget:`,
          `color: #6495ED;`,
          `color: #6495ED;`,
          idOfTarget
        );
      }
      modelForUpdate.id = idOfTarget;
      const res = await this.openUpdateModel(url, modelForUpdate, showLog);

      let returnData = null;

      if (res.data) {
        returnData = res.data;
      } else {
        returnData = res;
      }

      if (returnData === null) {
        returnData = { result: 0 };
      }
      if (showLog) {
        console.log(`%c[ return ] :`, `color: #6495ED;`, returnData);
      }
      return returnData;
    },

    // DELETE
    // [인증된 사용자] 를 위한 정보 삭제 API
    /**
     * @description [인증된 사용자] PK를 통한 BizNews 삭제 API
     * @param {String} url 요청 URL
     * @param {Number} idOfTarget 삭제할 BizNews의 PK
     * @param {Boolean} showLog 생성 결과를 로그에 표시할지 여부
     * @returns {Boolean} 수행 결과 (1: 성공, 0: 실패)
     */
    async authDeleteData(url, idOfTarget, showLog) {
      if (showLog) {
        console.log(
          `%c[ Mixin : MasterCtrl ] ` +
            `%c[ ${url} ]` +
            `%cauthDeleteBizNews() idOfTarget:`,
          `color: #6495ED;`,
          `color: #6495ED;`,
          `color: #6495ED;`,
          idOfTarget
        );
      }
      let dataObject = {
        data: {
          id: idOfTarget,
        },
      };
      const res = await this.authDeleteModel(url, dataObject, showLog);
      let returnData = res.data;
      if (showLog) {
        console.log(`%c[ return ] :`, `color: #6495ED;`, returnData);
      }
      return returnData;
    },
    /**
     * @description [인증되지 않은 사용자] PK를 통한 BizNews 삭제 API
     * @param {String} url 요청 URL
     * @param {Number} idOfTarget 삭제 BizNews의 PK
     * @param {Boolean} showLog 생성 결과를 로그에 표시할지 여부
     * @returns {Boolean} 수행 결과 (1: 성공, 0: 실패)
     */
    async openDeleteData(url, idOfTarget, showLog) {
      if (showLog) {
        console.log(
          `%c[ ${url} ]` +
            `%c[ Mixin : MasterCtrl ] openDeleteBizNews() idOfTarget:`,
          `color: #6495ED;`,
          `color: #6495ED;`,
          idOfTarget
        );
      }
      let dataObject = {
        data: {
          id: idOfTarget,
        },
      };
      const res = await this.openDeleteModel(url, dataObject, showLog);
      let returnData = res.data;
      if (showLog) {
        console.log(`%c[ return ] :`, `color: #6495ED;`, returnData);
      }
      return returnData;
    },

    // Model UHFile
    // READ
    /**
     * @description [인증된 사용자] UserId를 통한 UHFile 조회 API
     * @param {Number} userId 조회할 UHFile의 userId
     * @param {Boolean} showLog 생성 결과를 로그에 표시할지 여부
     * @returns {Array} 조회 결과 (Array: 성공, []: 실패)
     */
    async authReadUHFileListByUserId(userId, showLog) {
      if (showLog) {
        console.log(
          `%c[ Mixin : MasterCtrl ] authReadUHFileListByUserId() userId: ${userId}`,
          `color: #6495ED;`
        );
      }
      let paramsObject = {
        params: {
          user_id: userId,
        },
      };
      const res = await this.authReadModel(this.UHFile, paramsObject, showLog);
      let returnData = res.data;
      if (showLog) {
        console.log(`%c[ return ] :`, `color: #6495ED;`, returnData);
      }
      return returnData;
    },
    /**
     * @description [인증된 사용자] fileId를 통한 UHFile 조회 API
     * @param {Number} fileId 조회할 UHFile의 fileId
     * @param {Boolean} showLog 생성 결과를 로그에 표시할지 여부
     * @returns {Array} 조회 결과 (Array: 성공, []: 실패)
     */
    async authReadUHFileListByFileId(fileId, showLog) {
      if (showLog) {
        console.log(
          `%c[ Mixin : MasterCtrl ] authReadUHFileListByFileId() fileId: ${fileId}`,
          `color: #6495ED;`
        );
      }
      let paramsObject = {
        params: {
          file_id: fileId,
        },
      };
      const res = await this.authReadModel(this.UHFile, paramsObject, showLog);
      let returnData = res.data;
      if (showLog) {
        console.log(`%c[ return ] :`, `color: #6495ED;`, returnData);
      }
      return returnData;
    },
    /**
     * @description [인증되지 않은 사용자] UserId를 통한 UHFile 조회 API
     * @param {Number} userId 조회할 UHFile의 userId
     * @param {Boolean} showLog 생성 결과를 로그에 표시할지 여부
     * @returns {Array} 조회 결과 (Array: 성공, []: 실패)
     */
    async openReadUHFileListByUserId(userId, showLog) {
      if (showLog) {
        console.log(
          `%c[ Mixin : MasterCtrl ] openReadUHFileListByUserId() userId: ${userId}`,
          `color: #6495ED;`
        );
      }
      let paramsObject = {
        params: {
          user_id: userId,
        },
      };
      const res = await this.openReadModel(this.UHFile, paramsObject, showLog);
      let returnData = res.data;
      if (showLog) {
        console.log(`%c[ return ] :`, `color: #6495ED;`, returnData);
      }
      return returnData;
    },
    /**
     * @description [인증되지 않은 사용자] fileId를 통한 UHFile 조회 API
     * @param {Number} fileId 조회할 UHFile의 fileId
     * @param {Boolean} showLog 생성 결과를 로그에 표시할지 여부
     * @returns {Array} 조회 결과 (Array: 성공, []: 실패)
     */
    async openReadUHFileListByFileId(fileId, showLog) {
      if (showLog) {
        console.log(
          `%c[ Mixin : MasterCtrl ] openReadUHFileListByFileId() fileId: ${fileId}`,
          `color: #6495ED;`
        );
      }
      let paramsObject = {
        params: {
          file_id: fileId,
        },
      };
      const res = await this.openReadModel(this.UHFile, paramsObject, showLog);
      let returnData = res.data;
      if (showLog) {
        console.log(`%c[ return ] :`, `color: #6495ED;`, returnData);
      }
      return returnData;
    },

    // Model WHFile
    // READ
    /**
     * @description [인증된 사용자] WorkId를 통한 WHFile 조회 API
     * @param {Number} workId 조회할 WHFile의 workId
     * @param {Boolean} showLog 생성 결과를 로그에 표시할지 여부
     * @returns {Array} 조회 결과 (Array: 성공, []: 실패)
     */
    async authReadWHFileListByWorkId(workId, showLog) {
      if (showLog) {
        console.log(
          `%c[ Mixin : MasterCtrl ] authReadWHFileListByWorkId() workId: ${workId}`,
          `color: #6495ED;`
        );
      }
      let paramsObject = {
        params: {
          work_id: workId,
        },
      };
      const res = await this.authReadModel(this.WHFile, paramsObject, showLog);
      let returnData = res.data;
      if (showLog) {
        console.log(`%c[ return ] :`, `color: #6495ED;`, returnData);
      }
      return returnData;
    },
    /**
     * @description [인증된 사용자] fileId를 통한 WHFile 조회 API
     * @param {Number} fileId 조회할 WHFile의 fileId
     * @param {Boolean} showLog 생성 결과를 로그에 표시할지 여부
     * @returns {Array} 조회 결과 (Array: 성공, []: 실패)
     */
    async authReadWHFileListByFileId(fileId, showLog) {
      if (showLog) {
        console.log(
          `%c[ Mixin : MasterCtrl ] authReadWHFileListByFileId() fileId: ${fileId}`,
          `color: #6495ED;`
        );
      }
      let paramsObject = {
        params: {
          file_id: fileId,
        },
      };
      const res = await this.authReadModel(this.WHFile, paramsObject, showLog);
      let returnData = res.data;
      if (showLog) {
        console.log(`%c[ return ] :`, `color: #6495ED;`, returnData);
      }
      return returnData;
    },
    /**
     * @description [인증되지 않은 사용자] WorkId를 통한 WHFile 조회 API
     * @param {Number} workId 조회할 WHFile의 workId
     * @param {Boolean} showLog 생성 결과를 로그에 표시할지 여부
     * @returns {Array} 조회 결과 (Array: 성공, []: 실패)
     */
    async openReadWHFileListByWorkId(workId, showLog) {
      if (showLog) {
        console.log(
          `%c[ Mixin : MasterCtrl ] openReadWHFileListByWorkId() workId: ${workId}`,
          `color: #6495ED;`
        );
      }
      let paramsObject = {
        params: {
          work_id: workId,
        },
      };
      const res = await this.openReadModel(this.WHFile, paramsObject, showLog);
      let returnData = res.data;
      if (showLog) {
        console.log(`%c[ return ] :`, `color: #6495ED;`, returnData);
      }
      return returnData;
    },
    /**
     * @description [인증되지 않은 사용자] fileId를 통한 WHFile 조회 API
     * @param {Number} fileId 조회할 WHFile의 fileId
     * @param {Boolean} showLog 생성 결과를 로그에 표시할지 여부
     * @returns {Array} 조회 결과 (Array: 성공, []: 실패)
     */
    async openReadWHFileListByFileId(fileId, showLog) {
      if (showLog) {
        console.log(
          `%c[ Mixin : MasterCtrl ] openReadWHFileListByFileId() fileId: ${fileId}`,
          `color: #6495ED;`
        );
      }
      let paramsObject = {
        params: {
          file_id: fileId,
        },
      };
      const res = await this.openReadModel(this.WHFile, paramsObject, showLog);
      let returnData = res.data;
      if (showLog) {
        console.log(`%c[ return ] :`, `color: #6495ED;`, returnData);
      }
      return returnData;
    },

    // Model BNHFile
    // READ
    /**
     * @description [인증된 사용자] bizNewsId를 통한 BNHFile 조회 API
     * @param {Number} bizNewsId 조회할 BNHFile의 bizNewsId
     * @param {Boolean} showLog 생성 결과를 로그에 표시할지 여부
     * @returns {Array} 조회 결과 (Array: 성공, []: 실패)
     */
    async authReadBNHFileListByBizNewsId(bizNewsId, showLog) {
      if (showLog) {
        console.log(
          `%c[ Mixin : MasterCtrl ] authReadBNHFileListByBizNewsId() bizNewsId: ${bizNewsId}`,
          `color: #6495ED;`
        );
      }
      let paramsObject = {
        params: {
          biz_news_id: bizNewsId,
        },
      };
      const res = await this.authReadModel(this.BNHFile, paramsObject, showLog);
      let returnData = res.data;
      if (showLog) {
        console.log(`%c[ return ] :`, `color: #6495ED;`, returnData);
      }
      return returnData;
    },
    /**
     * @description [인증된 사용자] fileId를 통한 BNHFile 조회 API
     * @param {Number} fileId 조회할 BNHFile의 fileId
     * @param {Boolean} showLog 생성 결과를 로그에 표시할지 여부
     * @returns {Array} 조회 결과 (Array: 성공, []: 실패)
     */
    async authReadBNHFileListByFileId(fileId, showLog) {
      if (showLog) {
        console.log(
          `%c[ Mixin : MasterCtrl ] authReadBNHFileListByFileId() fileId: ${fileId}`,
          `color: #6495ED;`
        );
      }
      let paramsObject = {
        params: {
          file_id: fileId,
        },
      };
      const res = await this.authReadModel(this.BNHFile, paramsObject, showLog);
      let returnData = res.data;
      if (showLog) {
        console.log(`%c[ return ] :`, `color: #6495ED;`, returnData);
      }
      return returnData;
    },
    /**
     * @description [인증되지 않은 사용자] bizNewsId를 통한 BNHFile 조회 API
     * @param {Number} bizNewsId 조회할 BNHFile의 bizNewsId
     * @param {Boolean} showLog 생성 결과를 로그에 표시할지 여부
     * @returns {Array} 조회 결과 (Array: 성공, []: 실패)
     */
    async openReadBNHFileListByBizNewsId(bizNewsId, showLog) {
      if (showLog) {
        console.log(
          `%c[ Mixin : MasterCtrl ] openReadBNHFileListByBizNewsId() bizNewsId: ${bizNewsId}`,
          `color: #6495ED;`
        );
      }
      let paramsObject = {
        params: {
          biz_news_id: bizNewsId,
        },
      };
      const res = await this.openReadModel(this.BNHFile, paramsObject, showLog);
      let returnData = res.data;
      if (showLog) {
        console.log(`%c[ return ] :`, `color: #6495ED;`, returnData);
      }
      return returnData;
    },
    /**
     * @description [인증되지 않은 사용자] fileId를 통한 BNHFile 조회 API
     * @param {Number} fileId 조회할 BNHFile의 fileId
     * @param {Boolean} showLog 생성 결과를 로그에 표시할지 여부
     * @returns {Array} 조회 결과 (Array: 성공, []: 실패)
     */
    async openReadBNHFileListByFileId(fileId, showLog) {
      if (showLog) {
        console.log(
          `%c[ Mixin : MasterCtrl ] openReadBNHFileListByFileId() fileId: ${fileId}`,
          `color: #6495ED;`
        );
      }
      let paramsObject = {
        params: {
          file_id: fileId,
        },
      };
      const res = await this.openReadModel(this.BNHFile, paramsObject, showLog);
      let returnData = res.data;
      if (showLog) {
        console.log(`%c[ return ] :`, `color: #6495ED;`, returnData);
      }
      return returnData;
    },

    // Model BusinessKeyword
    /**
     * @description [인증된 사용자] workId를 통한 BusinessKeyword 조회 API
     * @param {Number} workId 조회할 BusinessKeyword의 workId
     * @param {Boolean} showLog 생성 결과를 로그에 표시할지 여부
     * @returns {Array} 조회 결과 (Array: 성공, []: 실패)
     */
    async authReadBusinessKeywordListByWorkId(workId, showLog) {
      if (showLog) {
        console.log(
          `%c[ Mixin : MasterCtrl ] authReadBusinessKeywordListByWorkId() workId: ${workId}`,
          `color: #6495ED;`
        );
      }
      let paramsObject = {
        params: {
          work_id: workId,
        },
      };
      const res = await this.authReadModel(
        this.BusinessKeyword,
        paramsObject,
        showLog
      );
      let returnData = res.data;
      if (showLog) {
        console.log(`%c[ return ] :`, `color: #6495ED;`, returnData);
      }
      return returnData;
    },
    /**
     * @description [인증되지 않은 사용자] workId를 통한 BusinessKeyword 조회 API
     * @param {Number} workId 조회할 BusinessKeyword의 workId
     * @param {Boolean} showLog 생성 결과를 로그에 표시할지 여부
     * @returns {Array} 조회 결과 (Array: 성공, []: 실패)
     */
    async openReadBusinessKeywordListByWorkId(workId, showLog) {
      if (showLog) {
        console.log(
          `%c[ Mixin : MasterCtrl ] openReadBusinessKeywordListByWorkId() workId: ${workId}`,
          `color: #6495ED;`
        );
      }
      let paramsObject = {
        params: {
          work_id: workId,
        },
      };
      const res = await this.openReadModel(
        this.BusinessKeyword,
        paramsObject,
        showLog
      );
      let returnData = res.data;
      if (showLog) {
        console.log(`%c[ return ] :`, `color: #6495ED;`, returnData);
      }
      return returnData;
    },
    //Model Wrok
    /**
     * @description [인증된 사용자] userId를 통한 Work 조회 API
     * @param {Number} userId 조회할 Work의 userId
     * @param {Boolean} showLog 생성 결과를 로그에 표시할지 여부
     * @returns {Array} 조회 결과 (Array: 성공, []: 실패)
     */
    async authReadWorkListByUserId(userId, showLog) {
      if (showLog) {
        console.log(
          `%c[ Mixin : MasterCtrl ] authReadWorkListByUserId() userId: ${userId}`,
          `color: #6495ED;`
        );
      }
      let paramsObject = {
        params: {
          user_id: userId,
        },
      };
      const res = await this.authReadModel(this.Work, paramsObject, showLog);
      let returnData = res.data;
      if (showLog) {
        console.log(`%c[ return ] :`, `color: #6495ED;`, returnData);
      }
      return returnData;
    },
    // Model Donation
    /**
     * @description [인증된 사용자] userId를 통한 Donation 조회 API
     * @param {Number} userId 조회할 Donation의 userId
     * @param {Boolean} showLog 생성 결과를 로그에 표시할지 여부
     * @returns {Array} 조회 결과 (Array: 성공, []: 실패)
     */
    async authReadDonationListByUserId(userId, showLog) {
      if (showLog) {
        console.log(
          `%c[ Mixin : MasterCtrl ] authReadDonationListByUserId() userId: ${userId}`,
          `color: #6495ED;`
        );
      }
      let paramsObject = {
        params: {
          user_id: userId,
        },
      };
      const res = await this.authReadModel(
        this.Donation,
        paramsObject,
        showLog
      );
      let returnData = res.data;
      if (showLog) {
        console.log(`%c[ return ] :`, `color: #6495ED;`, returnData);
      }
      return returnData;
    },
    // Model User
    /**
     * @description [인증된 사용자] userId를 통한 PW 변경 API
     * @param {Number} userId User의 userId
     * @param {String} password 변경할 PW
     * @param {Boolean} showLog 생성 결과를 로그에 표시할지 여부
     * @return {Boolean} 조회 결과 (1: 성공, 0: 실패)
     */
    async authUpdateUserPassword(userId, password, showLog) {
      if (showLog) {
        console.log(
          `%c[ Mixin : MasterCtrl ] authUpdateUserPassword() userId: ${userId}`,
          `color: #6495ED;`
        );
      }
      let paramsObject = {
        params: {
          id: userId,
          password: password,
        },
      };
      const res = await this.authUpdateModel(
        this.UserPWUpdate,
        paramsObject,
        showLog
      );
      let returnData = res.data;
      if (showLog) {
        console.log(`%c[ return ] :`, `color: #6495ED;`, returnData);
      }
      return returnData;
    },
  },
};
