import AWS from "aws-sdk";

export const AWSS3Ctrl = {
  data() {
    return {
      // AWS-S3 Info
      albumBucketName: "wisechurch-t-1",
      bucketRegion: "ap-northeast-2",
      IdentityPoolId: "ap-northeast-2:5e8b852b-4649-4451-a8e1-a831049355a1",
      // S3 Instunce
      s3: null,
    };
  },
  created() {},
  methods: {
    // set S3 instunce
    async setS3() {
      await AWS.config.update({
        region: this.bucketRegion,
        credentials: new AWS.CognitoIdentityCredentials({
          IdentityPoolId: this.IdentityPoolId,
        }),
      });
      this.s3 = await new AWS.S3({
        apiVersion: "2006-03-01",
        params: {
          Bucket: this.albumBucketName,
        },
      });
    },
    // clear S3 instunce
    async clearS3() {
      this.s3 = await null;
    },
    // AWS-S3 Create
    async s3CreateFile(fileName, fileObject, showLog) {
      if (showLog) {
        console.log(`%c[ mixins: AWSS3Ctrl ] s3CreateFile`, `color: #008080;`);

        console.log(`%cfileName :`, `color: #008080;`, fileName);
        console.log(`%cfileObject :`, `color: #008080;`, fileObject);
      }
      let tempData = null;
      await this.setS3();
      await this.s3
        .upload({
          Key: fileName,
          Body: fileObject,
          ACL: "public-read",
        })
        .promise()
        .then((res) => {
          if (showLog) {
            console.log(
              `%c[ ` + `%cread res` + `%c ] :`,
              `color: #008080;`,
              `color: #008080;`,
              `color: #008080;`,
              res
            );
          }
          tempData = {
            fileName: res.Key,
            filePath: res.Location,
          };
        })
        .catch((err) => {
          alert(
            "정보를 업로드하는데 실패하였습니다.(관리자문의 : 010-3383-4177)"
          );
          console.log(err);
        });
      this.clearS3();
      return tempData;
      // this.$router.go();
    },
    // AWS-S3 Read
    async s3ReadFileList() {
      await this.setS3();
      let tempData = await null;
      await this.s3
        .listObjectsV2({
          Bucket: this.albumBucketName,
        })
        .promise()
        .then((data) => {
          console.log("리스트 가져오기 성공");
          console.log(data);
          tempData = data.Contents;
        })
        .catch((err) => {
          console.log("리스트 가져오기 실패");
          console.log(err);
        });
      await this.clearS3();
      return tempData;
    },

    // AWS-S3 Get File
    async s3GetFile(fileName, logTogle) {
      if (logTogle) {
        console.log(`[ mixins: AWSS3Ctrl ] s3GetFile`);
        console.log(` fileName :`, fileName);
      }
      let tempData = null;
      await this.setS3();
      await this.s3
        .getObject({
          Bucket: this.albumBucketName,
          Key: fileName,
        })
        .promise()
        .then((res) => {
          console.log("파일 가져오기 성공");
          console.log(res);
          tempData = res.Body;
        })
        .catch((err) => {
          console.log("파일 가져오기 실패");
          console.log(err);
        });
      await this.clearS3();
      return tempData;
    },

    // AWS-S3 Update
    async s3UpdateFile(userEmail, fileName, fileObject) {
      console.log(`[ mixins: AWSS3Ctrl ] s3UpdateFile`);
      console.log(` userEmail :`, userEmail);
      console.log(` fileName :`, fileName);
      console.log(` fileObject :`, fileObject);
      await this.setS3();
      let tempData = await null;
      await this.s3
        .upload({
          Key: userEmail + "_" + fileName,
          Body: fileObject,
          ACL: "public-read",
        })
        .promise()
        .then((data) => {
          alert("업데이트 성공");
          console.log("업데이트 성공");
          console.log(data);
          tempData = data;
        })
        .catch((err) => {
          console.log("업데이트 실패");
          console.log(err);
        });
      await this.clearS3();
      return tempData;
    },
    // AWS-S3 Delete
    async s3DeleteFile(key, showLog) {
      if (showLog) {
        console.log(`%c[ mixins: AWSS3Ctrl ] s3DeleteFile`, `color: #008080;`);
        console.log(`%c key :`, `color: #008080;`, key);
      }

      await this.setS3();
      let tempData = await null;
      await this.s3
        .deleteObject({
          Bucket: this.albumBucketName,
          Key: key,
        })
        .promise()
        .then((data) => {
          if (showLog) {
            console.log(
              `%c[ ` + `%cdata` + `%c ] :`,
              `color: #008080;`,
              `color: #008080;`,
              `color: #008080;`,
              data
            );
          }
          tempData = data;
        })
        .catch((err) => {
          console.log("삭제 실패");
          console.log(err);
        });
      await this.clearS3();
      return tempData;
    },
  },
};
