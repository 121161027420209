import Vue from "vue";
import VueRouter from "vue-router";
import ECList from "@/views/clean/ECList.vue";

// Layouts
import MainLayout from "@/layouts/main/Index.vue";
import BizLayout from "@/layouts/biz/Index.vue";
import SubLayout from "@/layouts/sub/Index.vue";
import MyPageLayout from "@/layouts/mypage/Index.vue";
import CleanLayout from "@/layouts/clean/Index.vue";

// Views
// MainLayout
import Home from "@/views/main/Home.vue";
import Industrialist from "@/views/main/Industrialist.vue";
import IndustrialistDetail from "@/views/main/IndustrialistDetail.vue";

import YewonBiz from "@/views/main/YewonBiz.vue";
import YewonBizNews from "@/views/main/YewonBizNews.vue";
import YewonBizNewsDetail from "@/views/main/YewonBizNewsDetail.vue";

// MyPageLayout
import UserInfo from "@/views/mypage/UserInfo.vue";
import ResetPW from "@/views/mypage/ResetPW.vue";
import WorkInfo from "@/views/mypage/WorkInfo.vue";
import CMSInfo from "@/views/mypage/CMSInfo.vue";

// SubLayout
import SignUp from "@/views/sub/SignUp.vue";
import FindEmail from "@/views/sub/FindEmail.vue";
import FindEmailResult from "@/views/sub/FindEmailResult.vue";
import ResetPwOutside from "@/views/sub/ResetPwOutside.vue";
import ResetPwOutsidePage from "@/views/sub/ResetPwOutsidePage.vue";

// CleanLayout
import Login from "@/views/clean/Login.vue";
import CtrlTest from "@/views/clean/CtrlTest.vue";
import AxiosTest from "@/views/test/AxiosTest.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "",
    component: MainLayout,
    children: [
      {
        path: "/home",
        name: "Home",
        component: Home,
      },
      {
        path: "/",
        name: "Industrialist",
        component: Industrialist,
      },
      {
        path: "industrialist/:keyword",
        name: "IndustrialistByKeyword",
        component: Industrialist,
      },
      {
        path: "yewon-biz",
        name: "YewonBiz",
        component: YewonBiz,
      },
      {
        path: "yewon-biz-news",
        name: "YewonBizNews",
        component: YewonBizNews,
      },
    ],
  },
  {
    path: "/biz",
    name: "BizLayout",
    component: BizLayout,
    children: [],
  },
  {
    path: "/sub",
    name: "SubLayout",
    component: SubLayout,
    children: [
      {
        path: "login",
        name: "Login",
        component: Login,
      },
      {
        path: "sign-up",
        name: "SignUp",
        component: SignUp,
      },
      {
        path: "find-email",
        name: "FindEmail",
        component: FindEmail,
      },
      {
        path: "find-email-result/:email",
        name: "FindEmailResult",
        component: FindEmailResult,
      },
      {
        path: "find-pw",
        name: "ResetPwOutside",
        component: ResetPwOutside,
      },
      {
        path: "reset-pw-outside-page/:userEmail/:userId",
        name: "ResetPwOutsidePage",
        component: ResetPwOutsidePage,
      },
      {
        path: "industrialist-detail/:workId",
        name: "IndustrialistDetail",
        component: IndustrialistDetail,
      },
      {
        path: "biznews-detail/:bizNewsId",
        name: "YewonBizNewsDetail",
        component: YewonBizNewsDetail,
      },
    ],
  },
  {
    path: "/mypage",
    name: "MyPageLayout",
    component: MyPageLayout,
    children: [
      {
        path: "user-info",
        name: "UserInfo",
        component: UserInfo,
      },
      {
        path: "reset-pw",
        name: "ResetPW",
        component: ResetPW,
      },
      {
        path: "work-info",
        name: "WorkInfo",
        component: WorkInfo,
      },
      {
        path: "cms-info",
        name: "CMSInfo",
        component: CMSInfo,
      },
    ],
  },
  {
    path: "/clean",
    name: "CleanLayout",
    component: CleanLayout,
    children: [
      {
        path: "ec-list",
        name: "ECList",
        component: ECList,
      },
      {
        path: "ctrl-test",
        name: "CtrlTest",
        component: CtrlTest,
      },
      {
        path: "axios-test",
        name: "AxiosTest",
        component: AxiosTest,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
