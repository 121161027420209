import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import axios from "axios";
import router from "../router/index";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLogin: false,
    userInfo: {},
    // Database
    workList: [],
    userList: [],
    whfileList: [],
    uhfileList: [],
    fileList: [],
    businessKeywordList: [],
  },
  mutations: {
    setLogin(state, payload) {
      state.isLogin = payload.isLogin;
      state.userInfo = payload.userInfo;
    },
    setLogout(state) {
      state.isLogin = false;
      state.userInfo = {};
    },
    // workList,userList,whfileList,uhfileList,fileList,businessKeywordList를 한번에 받아온다
    setDatabase(state, payload) {
      state.workList = payload.workList;
      state.userList = payload.userList;
      state.whfileList = payload.whfileList;
      state.uhfileList = payload.uhfileList;
      state.fileList = payload.fileList;
      state.businessKeywordList = payload.businessKeywordList;
    },
  },
  actions: {
    login: async ({ commit }, payload) => {
      if (payload.userEmail.trim() == "") {
        alert("아이디를 입력하세요");
        return;
      }
      if (payload.userPw.trim() == "") {
        alert("비밀번호를 입력하세요");
        return;
      }

      await axios
        .post("https://www.yewon-remnant.net/api/login", {
          email: payload.userEmail.trim(),
          password: payload.userPw.trim(),
        })
        .then((res) => {
          if (res.data == "") {
            alert("로그인 정보가 정확하지 않습니다");
            return;
          }
          if (res.data == "Not Allowed") {
            console.log("비밀번호 불일치");
            alert("로그인 정보가 정확하지 않습니다");
            return;
          }
          console.log(res);
          commit("setLogin", {
            isLogin: true,
            userInfo: res.data.userInfo,
          });

          localStorage.setItem("is_login", true);
          localStorage.setItem("user_info", JSON.stringify(res.data.userInfo));
          localStorage.setItem("access_token", res.data.accessToken);
          localStorage.setItem("refresh_token", res.data.refreshToken);
          router.push({ path: "/" });
        })
        .catch((err) => {
          alert("로그인 정보가 정확하지 않습니다");
          console.log("로그인 정보가 정확하지 않습니다");
          console.log(err);
          return;
        });
    },
    hideLogin: async ({ commit }, newUserInfo) => {
      console.log("[ACTION] hideLogin");
      console.log("[PAYLOAD] newUserInfo");
      console.log(newUserInfo);
      if (newUserInfo.email.trim() == "") {
        alert("이미 존재하는 이메일입니다");
        return false;
      }

      await axios
        .post("https://www.yewon-remnant.net/api/login", {
          email: newUserInfo.email.trim(),
          password: newUserInfo.password.trim(),
          // email: "a@a.com",
          // password: "admin",
        })
        .then((res) => {
          if (res.data == "") {
            alert("로그인 정보가 정확하지 않습니다");
            return;
          }
          console.log("HideLogin Res");
          console.log(res);
          commit("setLogin", {
            isLogin: true,
            userInfo: res.data.userInfo,
          });

          localStorage.setItem("is_login", true);
          localStorage.setItem("user_info", JSON.stringify(res.data.userInfo));
          localStorage.setItem("access_token", res.data.accessToken);
          localStorage.setItem("refresh_token", res.data.refreshToken);
        })
        .catch((err) => {
          console.log("로그인 정보가 정확하지 않습니다");
          console.log(err);
          return;
        });
      return true;
      // this.$router.push({ name: "home" });
    },
    checkLogin: ({ commit }) => {
      if (localStorage.getItem("is_login")) {
        commit("setLogin", {
          isLogin: true,
          userInfo: JSON.parse(localStorage.getItem("user_info")),
        });
      } else {
        return false;
      }
    },
    refreshAccessTokenAtVuex: () => {
      console.log("refresh");
      axios
        .post("https://www.yewon-remnant.net/api/refresh", {
          refreshToken: localStorage.getItem("refresh_token"),
        })
        .then((res) => {
          console.log("refresh res", res);
          localStorage.removeItem("access_token");
          localStorage.setItem("access_token", res.data.accessToken);
        })
        .catch((err) => {
          console.log("refresh err", err);
        });
    },
    logout({ commit }) {
      localStorage.removeItem("is_login");
      localStorage.removeItem("user_info");
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      commit("setLogout");
      // router.push({ name: "/" });
    },
    // workList,userList,whfileList,uhfileList,fileList,businessKeywordList를 한번에 받아온다
    getDatabase: async ({ commit }) => {
      const baseAxios = axios.create({
        baseURL: "https://www.yewon-remnant.net/api",
        headers: {
          authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      });

      const [
        { data: workList },
        { data: userList },
        { data: whfileList },
        { data: uhfileList },
        { data: fileList },
        { data: businessKeywordList },
      ] = await Promise.all([
        baseAxios.get("/works"),
        baseAxios.get("/users"),
        baseAxios.get("/whfiles"),
        baseAxios.get("/uhfiles"),
        baseAxios.get("/files"),
        baseAxios.get("/business_keywords"),
      ]);
      commit("setDatabase", {
        workList,
        userList,
        whfileList,
        uhfileList,
        fileList,
        businessKeywordList,
      });
    },
  },
  modules: {},
  plugins: [createPersistedState()],
});

// import { mapState, mapActions } from "vuex";
// ...mapState(["isLogin", "userInfo"]),
// ...mapActions(["hideLogin","login","checkLogin","refreshAccessTokenAtVuex","logout",]),
