<template>
  <v-container fill-height fluid>
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="10" sm="7" md="6" lg="4" xl="4">
        <div class="nm" :style="search_bar_sytle">
          <v-container>
            <v-row>
              <v-col cols="1" sm="1" md="1" lg="1" xl="1">
                <v-avatar
                  v-if="$vuetify.breakpoint.lgAndUp"
                  size="40"
                  class="ma-4"
                >
                  <img :src="require(`@/assets/logo_nk.png`)" alt="John" />
                </v-avatar>
                <v-avatar v-if="$vuetify.breakpoint.md" size="38" class="ma-4">
                  <img :src="require(`@/assets/logo_nk.png`)" alt="John" />
                </v-avatar>
                <v-avatar v-if="$vuetify.breakpoint.sm" size="38" class="ma-4">
                  <img :src="require(`@/assets/logo_nk.png`)" alt="John" />
                </v-avatar>
                <v-avatar v-if="$vuetify.breakpoint.xs" size="32" class="ml-2">
                  <img :src="require(`@/assets/logo_nk.png`)" alt="John" />
                </v-avatar>
              </v-col>
              <v-col cols="7" sm="8" md="9" lg="9" xl="9" class="pl-7 pt-0">
                <v-text-field
                  v-if="$vuetify.breakpoint.mdAndUp"
                  v-model="keyword"
                  class="ml-2 mt-6 bg-transparent"
                  label="Search "
                  flat
                  solo
                  @keyup.enter="searchKeyword()"
                ></v-text-field>
                <v-text-field
                  v-if="$vuetify.breakpoint.sm"
                  v-model="keyword"
                  class="ml-3 mt-5 bg-transparent"
                  label="Search "
                  flat
                  solo
                  @keyup.enter="searchKeyword()"
                ></v-text-field>
                <v-text-field
                  v-if="$vuetify.breakpoint.xs"
                  v-model="keyword"
                  class="ml-1 mt-1 bg-transparent"
                  label="Search "
                  style="font-size: 14px"
                  flat
                  solo
                  @keyup.enter="searchKeyword()"
                ></v-text-field>
              </v-col>
              <v-col cols="3" sm="2" md="2" lg="2" xl="2" class="pr-0 pt-0">
                <v-btn
                  v-if="$vuetify.breakpoint.mdAndUp"
                  class="mt-3 mr-1"
                  text
                  fab
                  x-large
                  @click="searchKeyword()"
                >
                  <v-icon color="#5E5E60" dark> mdi-magnify </v-icon>
                </v-btn>
                <v-btn
                  v-if="$vuetify.breakpoint.sm"
                  class="mt-3 ml-5"
                  text
                  fab
                  x-large
                  @click="searchKeyword()"
                >
                  <v-icon color="#5E5E60" dark> mdi-magnify </v-icon>
                </v-btn>

                <v-btn
                  v-if="$vuetify.breakpoint.xs"
                  text
                  class="pr-0 ml-8"
                  fab
                  @click="searchKeyword()"
                  @keyup.enter="searchKeyword()"
                >
                  <v-icon color="#5E5E60" dark> mdi-magnify </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <!-- <v-row>
              <v-spacer></v-spacer>
              <v-col cols="auto">
                <router-link
                  :to="{ path: '/sub/sign-up' }"
                  class="text-decoration-none grey--text text--darken-1"
                >
                  회원가입</router-link
                >
              </v-col>
              <v-spacer></v-spacer>
            </v-row> -->
          </v-container>
        </div>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </v-container>
</template>

<script>
// VUEX
import { mapState } from "vuex";
// MIXINS
// for Data CRUD
import { MasterCtrl } from "@/mixins/apis_v2/internal/MasterCtrl";
// for File Control
import { FileBins } from "@/mixins/apis_v2/internal/FileBins";
// for Utility
import { Utility } from "@/mixins/apis_v2/utility/Utility";

export default {
  name: "Home",
  data() {
    return {
      keyword: "",
      userProfileImage: [],
      profileFilepath: "",
    };
  },
  computed: {
    ...mapState(["isLogin", "userInfo"]),
    // 브래이크포인트에 따른 서치바 높이 조절
    search_bar_sytle() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "height: 3.5rem; margin-top: -30px;";
        case "sm":
          return "height: 6.0rem; margin-top: -60px;";
        case "md":
          return "height: 6.0rem; margin-top: -50px;";
        case "lg":
          return "height: 6.0rem; margin-top: -80px;";
        case "xl":
          return "height: 6.3rem; margin-top: -120px;";
        default:
          return "height: 3.5rem; margin-top: -50px;";
      }
    },
  },
  mixins: [MasterCtrl, FileBins, Utility],
  mounted() {},
  methods: {
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1
      );
    },
    searchKeyword() {
      if (this.keyword.trim() == "") {
        alert("검색어를 입력해주세요.");
        return;
      }
      let tempUrl = "/industrialist/" + this.keyword;
      this.$router.push(tempUrl);
    },
  },
};
</script>

<style>
/* .v-text-field input {
  font-size: 1.3em;
  line-height: 1.5em;
}

.v-input .v-label {
  font-size: 1.3em;
  line-height: 1.5em;
} */
</style>
