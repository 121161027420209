<template>
  <router-view />
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
  methods: {
    testFunction: () => {},
  },
};
</script>
